import React, { FC } from 'react';
import { FeatureFlagForm } from './FeatureFlagForm';
import { FeatureFlag } from '../index';
import { useSuperuserUpdateOrganizationMutation } from '../../../hooks/mutations/useSuperuserUpdateOrganization';
import { message } from 'antd';
import { GetViewerOrganization } from '../../../hooks/queries/useGetViewerOrganizationQuery';

interface Props {
  organizationId: string;
  enabledFlags?: FeatureFlag[];
}

export const FeatureFlagCard: FC<Props> = ({ organizationId, enabledFlags }): JSX.Element => {
  const [updateOrganization] = useSuperuserUpdateOrganizationMutation();

  const handleSubmit = async (featureFlagIds: string[]): Promise<void> => {
    await updateOrganization({
      variables: {
        organizationId: organizationId,
        superuserUpdateOrganizationInput: {
          featureFlagIds: featureFlagIds,
        },
      },
      refetchQueries: [{ query: GetViewerOrganization, variables: { organizationId: organizationId } }],
    });

    // reload the page
    window.location.reload();

    message.success('Your changes have been saved.');
  };

  return <FeatureFlagForm enabledFlags={enabledFlags} onSubmit={handleSubmit} />;
};
